import {useStaticQuery, graphql} from 'gatsby';

const fatbubu = () => {
  const data =  useStaticQuery (
    graphql`
      query {
        cat:file(relativePath: {eq: "fatbubu.jpg"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }`
  );
  return data;
};

export default fatbubu;