import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import fatbubu from "../components/fatbubu";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Trans } from "gatsby-plugin-react-i18next";

function NotFoundPage() {
  const catImage = fatbubu();
  return (
    <Layout>
      <SEO title="404: Not found" description="404 page" />
      <div>
        <h1 className="text-5xl font-heading text-red-500 text-center pt-8">
          <Trans>Page Not Found</Trans>
        </h1>
        <h2 className="text-2xl font-bold my-8 pb-3 text-center">
          <Trans>
            Looks like this page is a ghost that got abducted by aliens...
          </Trans>
        </h2>
        <main className="flex flex-col flex-1 md:justify-center max-w-2xl mx-auto px-4 py-8 md:p-8 w-full">
          <Img
            fluid={catImage.cat.childImageSharp.fluid}
            sizes={{
              ...catImage.cat.childImageSharp.fluid,
              aspectRatio: 449 / 600,
            }}
            loading="lazy"
            className="rounded"
          />
        </main>
      </div>
    </Layout>
  );
}

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "404", "header", "newsletter"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
